
import Vue from '@/vueTyped';
import { Investigation, Post } from '@/types';
import orderBy from 'lodash/orderBy';
import AppDownloadLinks from '@/components/AppDownloadLinks.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import PrimaryLogo from '@/components/logo/Primary.vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import { MetaInfo } from 'vue-meta';
import RouteNames from '@/router/names';
import { trackInFacebook } from '@/tracking';
import { CLIENT_ORIGIN } from '@/config';

type Content = {
    facebookPixelId?: string;
    pageTitle: string;
    heroImage: string;
    heroImage2x?: string;
    headline: string;
    intro: string;
    partnerSlug: string;
    investigations: Investigation['slug'][];
    posts: Post['id'][];
    howItWorksHeadline: string,
    topicsHeadline: string,
    postsHeadline: string,
};

export default Vue.extend({
    components: {
        AppDownloadLinks,
        LoadingIndicator,
        MarkdownOutput,
        PrimaryLogo,
        RegistrationForm,
    },

    metaInfo(): MetaInfo {
        return {
            title: this.content.pageTitle,
        };
    },

    data() {
        return {
            scrollObserver: null as IntersectionObserver | null,
            formIsVisible: true,
            fetchingPosts: false,
            posts: [] as Post[],
        };
    },

    computed: {
        // We'll automatically correct any case/hyphenation differences.
        caseCorrectedPartnerKey(): string {
            const simplify = (fragment: string) => fragment.toUpperCase().replace(/\W/g, '');
            const givenPartner = simplify(this.$route.params.partner);
            const knownPartnerKeys = Object.keys(this.$t('partnerLandingPages'));
            const matchingPartnerKey = knownPartnerKeys.find(key => simplify(key) === givenPartner);
            return matchingPartnerKey ?? this.$route.params.partner;
        },

        content(): Content {
            return {
                ...this.$t(`partnerLandingPages._defaults`, 'en') as Object,
                ...this.$t(`partnerLandingPages._defaults`) as Object,
                ...this.$t(`partnerLandingPages.${this.caseCorrectedPartnerKey}`, 'en') as Object,
                ...this.$t(`partnerLandingPages.${this.caseCorrectedPartnerKey}`) as Object,
            } as Content;
        },

        investigations(): Investigation[] {
            // Investigations are fetched on sign-in.
            let allInvestigations = this.$store.state.investigations.items;

            if (allInvestigations) {
                return this.content.investigations.map(slug => {
                    return allInvestigations.find(investigation => investigation.slug === slug);
                }).filter(Boolean) as Investigation[];
            } else {
                return [];
            }
        },

        shareProps(): object {
            return {
                url: new URL(CLIENT_ORIGIN).href,
                title: 'ISeeChange | Community Climate and Weather Journal',
                hashtags: 'iseechange,climatechange',
            };
        },
    },

    watch: {
        '$route.params.partner': {
            immediate: true,
            handler() {
                this.trackInFacebook('ViewContent', { path: this.$route.fullPath });
            },
        },

        caseCorrectedPartnerKey: {
            immediate: true,
            handler(caseCorrectedPartnerKey) {
                if (caseCorrectedPartnerKey && caseCorrectedPartnerKey !== this.$route.params.partner) {
                    this.$router.replace({ params: {partner: caseCorrectedPartnerKey } });
                }
            },
        },
    },

    async mounted() {
        const navHeight = getComputedStyle(document.documentElement).getPropertyValue('--webnavigation--height');
        this.scrollObserver = new IntersectionObserver(this.handleIntersectionChange, { rootMargin: `-${navHeight.trim()} 0px` });
        this.scrollObserver.observe(this.$refs.registrationForm as Element);
        this.fetchPosts();
    },

    beforeDestroy() {
        this.scrollObserver?.disconnect();
    },

    methods: {
        trackInFacebook(event: string, properties: Record<string, unknown> = {}) {
            if (this.content.facebookPixelId) {
                trackInFacebook(this.content.facebookPixelId, event, {
                    locale: this.$i18n.locale,
                    ref: this.$route.query.ref,
                    ...properties
                });
            }
        },

        handleIntersectionChange(entries: IntersectionObserverEntry[]) {
            const { intersectionRatio } = entries[0];
            this.formIsVisible = intersectionRatio !== 0;
        },

        async fetchPosts() {
            this.fetchingPosts = true;

            const posts = await Promise.all(this.content.posts.map(async id => {
                const { data } = await this.$store.dispatch('fetchPostById', { id });
                return data;
            }));

            this.posts = posts.filter(Boolean);

            for (const post of this.posts) {
                post.photoObjs = orderBy(post.photoObjs, 'created_at') as Post['photoObjs'];
            }

            this.fetchingPosts = false;
        },

        handleAppDownloadLinkClick(platform: string) {
            if (this.content.facebookPixelId) {
                this.trackInFacebook('Click app download', { platform });
            }
        },

        scrollToRegistrationForm() {
            (this.$refs.registrationForm as Element).scrollIntoView({ behavior: 'smooth' });
        },

        handleRegistrationSuccess() {
            this.trackInFacebook('CompleteRegistration');

            this.$router.push({
                name: RouteNames.REGISTER,
                query: {
                    redirect: this.$router.resolve({
                        name: RouteNames.REGISTER,
                        query: {
                            ...this.$route.query,
                            show: 'apps',
                        }
                    }).href,
                },
            });
        },

        getCurrentLocation(): string {
            return location.href;
        }
    },
});
