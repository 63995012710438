
import Vue from '@/vueTyped';
import { App } from '@capacitor/app';

export default Vue.extend({
    data() {
        return {
            state: 0,
            buildDate: null as Date | null,
            version: '',
        };
    },

    async mounted() {
        const buildDate = (document.querySelector('meta[name="build-date"]') as HTMLMetaElement | null)?.content ?? '';
        this.buildDate = new Date(buildDate);

        const appInfo = await App.getInfo().catch(() => null);

        if (appInfo !== null) {
            this.version = `${appInfo.version} (${appInfo.build})`;
        } else {
            const version = (document.querySelector('meta[name="git-description"]') as HTMLMetaElement | null)?.content ?? '';
            this.version = version.replace('dirty', '*');
        }
    },
});
