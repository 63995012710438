
import Vue from '@/vueTyped';
import ReleaseInfo from '@/components/ReleaseInfo.vue';
import ExperimentsMenu from './ExperimentsMenu.vue';

export default Vue.extend({
    components: {
        ExperimentsMenu,
        ReleaseInfo,
    },
});
