
import Vue from '@/vueTyped';
import PrimaryLogo from '@/components/logo/Primary.vue';
import FooterLinks from '@/components/FooterLinks.vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import RouteNames from '@/router/names';
import { CurrentUser } from '@/types';
import AppDownloadLinks from '@/components/AppDownloadLinks.vue';

export default Vue.extend({
    metaInfo(this: any, ) {
        return {
            title: this.pageTitle,
        };
    },

    components: {
        PrimaryLogo,
        RegistrationForm,
        FooterLinks,
        MarkdownOutput,
        AppDownloadLinks,
    },

    data(this: any) {
        return {
            pageTitle: this.t('title'),
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },

    methods: {
        t(path: string) {
            return this.$t(`campaignLandingPages.${this.$route.params.campaign}.${path}`);
        },

        handleRegistrationSuccess() {
            this.$router.push({
                name: RouteNames.REGISTER,
                query: {
                    redirect: this.$router.resolve({
                        name: RouteNames.REGISTER,
                        query: {
                            ...this.$route.query,
                            show: 'apps',
                        }
                    }).href,
                },
            });
        },
    },
});
